<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-10-15 01:44:34
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-12-04 14:20:29
 * @FilePath: \cloud\src\views\products.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div v-if="showHomeAndroid == false">
      <div style="  margin-top: 120px;">
        <div class="product-section">
          <div class="product-description">
            <h4 class="tagline">Advanced 3 in 1 Joint care complex</h4>
            <div class="description">
              <h4 class="tagline2">Each tablet contains</h4>
              Glucosamine hydrochloride 1.5 g
              Dimethyl sulfone (MSM) 250 mg
              Shark chondroitin sulfate 55.56 mg

              <h4 class="tagline2">Functions:</h4>

              No Potassium, One a Day, Improved absorption
              Helps relieve mild joint pain and inflammation associated with osteoarthritis of the knee.
              Supports joint health, mobility, and flexibility, and relieves mild joint stiffness.
              Maintains and supports joint cartilage health.

              <h4 class="tagline2">Direction for use:</h4>
              Take 1 tablet daily with water or as advised by your healthcare professional.
              Storage: Store below 25 ºC in a dry place

              <h4 class="tagline3">AUSTL463468</h4>

            </div>


            <div class="cent">
              <div class="product-image">
                <img src="@/assets/Advanced/26-1-ya.png" alt="Advanced Joint Care Complex" />
                <img src="@/assets/Advanced/27-ya.png" alt="Advanced Joint Care Complex" />
                <img src="@/assets/Advanced/30-ya.png" alt="Advanced Joint Care Complex" />
              </div>
            </div>



          </div>


        </div>






        <div class="contact-section" @mouseover="showText" @mouseleave="hideText">
          <h2 :class="{ 'visible': isTextVisible }" class="contact-heading">Let's talk</h2>
          <p :class="{ 'visible': isTextVisible }" class="contact-text">We would love to hear from you!</p>
          <a href="/" class="contact-button">GET IN TOUCH</a>
        </div>





      </div>
    </div>



    <div v-if="showHomeAndroid == true">
      <div style="  margin-top: 120px;">
        <div class="product-section">
          <div class="product-description">
            <h4 class="tagline">Advanced 3 in 1 Joint care complex</h4>
            <div class="description">
              <h4 class="tagline2">Each tablet contains</h4>
              <p class="text">
                Glucosamine hydrochloride 1.5 g
                Dimethyl sulfone (MSM) 250 mg
                Shark chondroitin sulfate 55.56 mg
              </p>
              <h4 class="tagline2">Functions:</h4>
              <p class="text">
                No Potassium, One a Day, Improved absorption
                Helps relieve mild joint pain and inflammation associated with osteoarthritis of the knee.
                Supports joint health, mobility, and flexibility, and relieves mild joint stiffness.
                Maintains and supports joint cartilage health.
              </p>
              <h4 class="tagline2">Direction for use:</h4>
              Take 1 tablet daily with water or as advised by your healthcare professional.
              Storage: Store below 25 ºC in a dry place

              <h4 class="tagline3">AUSTL463468</h4>

            </div>

            <div></div>
          </div>


        </div>



        <div class="cent">
          <div class="product-image">
            <img src="@/assets/Advanced/26-1-ya.png" alt="Advanced Joint Care Complex" />
            <img src="@/assets/Advanced/27-ya.png" alt="Advanced Joint Care Complex" />
            <img src="@/assets/Advanced/30-ya.png" alt="Advanced Joint Care Complex" />
          </div>
        </div>


      </div>



      <div class="contact-section" @mouseover="showText" @mouseleave="hideText">
        <h2 :class="{ 'visible': isTextVisible }" class="contact-heading">Let's talk</h2>
        <p :class="{ 'visible': isTextVisible }" class="contact-text">We would love to hear from you!</p>
        <a href="/" class="contact-button">GET IN TOUCH</a>
      </div>

    </div>
  </div>
</template>



<script>
export default {
  data() {
    return {
      showHomeAndroid: false,
      screenWidth: null,

      isTextVisible: true,
    }

  },

  mounted() {
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
    
  },


  watch: {
    screenWidth: {
      handler: function (val) {
        if (val >= 800) {
          this.bodyScale();
          this.showHomeAndroid = false;
          console.log(this.showHomeAndroid);
        } else if (val < 800) {
          this.showHomeAndroid = true;
          console.log(this.showHomeAndroid);
        }
        this.$forceUpdate();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    bodyScale() {
      var devicewidth = document.documentElement.clientWidth; //获取当前分辨率下的可是区域宽度
      var scale = devicewidth / 1600; // 分母——设计稿的尺寸
      document.body.style.zoom = scale; //放大缩小相应倍数
    },

    showText() {
      this.isTextVisible = true;
    },
    hideText() {
      this.isTextVisible = true;
    }
  }




}
</script>








<style>
body {
  overflow-y: scroll;
}

/* Module Styling */
.contact-section {
  background-color: #28a745; /* Green background */
  color: #fff;
  text-align: center;
  padding: 50px 20px;
  position: relative;
  transition: all 0.3s ease;
}

.contact-heading, .contact-text {
  opacity: 0; /* Initially hidden */
  transition: opacity 0.5s ease;
}

.contact-heading.visible, .contact-text.visible {
  opacity: 1; /* Show text when hovered */
}

.contact-button {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #fff;
  color: #28a745;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.contact-button:hover {
  background-color: #e0e0e0;
}




/* 手机端样式 */
@media (max-width: 768px) {

  .cent {
    margin-top: 100px;
    display: flex;
    justify-items: center;
    justify-content: center;
  }

  /* 主体布局 */
  .product-section {


    padding: 20px;
    width: 80%;
    margin: 0 auto;
    /* 让页面居中 */
  }

  /* 左侧文字样式 */
  .product-description {
    flex: 1;
    /* 文字占据 1 单位的空间 */
    padding-right: 20px;
  }

  .tagline {
    color: #28a745;
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 10px;
  }

  .tagline2 {
    color: #28a745;
    font-weight: bold;
    font-size: 1.3rem;
    margin-bottom: 5px;
    margin-top: 50px;
  }

  .tagline3 {
    color: #828282;
    text-align: right;
    font-weight: bold;
    font-size: 0.8rem;
    margin-bottom: 10px;
  }

  .description {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #333;
  }

  /* 右侧图片样式 */
  .product-image {
    flex: 1;
    /* 图片占据 1 单位的空间 */
    max-width: 100%;
    text-align: right;
    /* 确保图片在右侧对齐 */
  }

  .product-image img {
    width: 100%;
    /* 图片占满容器宽度 */
    height: auto;
    /* 保持图片的纵横比 */
    border-radius: 5px;
    /* 图片的圆角样式，根据需要调整 */
  }
}



/* PC端样式 */
@media (min-width: 768px) {

  .cent {
    margin-top: 100px;
    display: flex;
    justify-items: center;
    justify-content: center;
  }

  /* 主体布局 */
  .product-section {


    padding: 20px;
    width: 53%;
    margin: 0 auto;
    /* 让页面居中 */
  }

  /* 左侧文字样式 */
  .product-description {
    flex: 1;
    /* 文字占据 1 单位的空间 */
    padding-right: 20px;
  }

  .tagline {
    color: #28a745;
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .tagline2 {
    color: #28a745;
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .tagline3 {
    color: #828282;
    text-align: right;
    font-weight: bold;
    font-size: 0.8rem;
    margin-bottom: 10px;
  }

  .description {
    font-size: 1rem;
    line-height: 1.6;
    color: #333;
  }

  /* 右侧图片样式 */
  .product-image {
    flex: 1;
    /* 图片占据 1 单位的空间 */
    max-width: 100%;
    text-align: right;
    /* 确保图片在右侧对齐 */
  }

  .product-image img {
    width: 100%;
    /* 图片占满容器宽度 */
    height: auto;
    /* 保持图片的纵横比 */
    border-radius: 5px;
    /* 图片的圆角样式，根据需要调整 */
  }
}
</style>