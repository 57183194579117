<template>
  <div class="main">
    <div class="container">
      <!-- 联系我们部分 -->
      <div class="contact-us-section">
        <div class="contact-us-content">
          <h1 style="text-align: center;">关于我们</h1>
          <div style="width: 50%; margin: 0 auto;">
            <p style="text-align: center">
            光辉云，2017年成立于中国深圳市，作为AWS官方正式授权的精英代理商，我们自豪地站在云计算技术的前沿，致力于为企业和开发者提供最为专业、高效、安全的云计算解决方案。这一殊荣不仅体现了AWS对我们技术实力与服务品质的认可，更是对我们过去七年不懈努力的肯定。
          </p>
          </div>
        </div>
        <!-- <div class="contact-us-image">
  
          </div> -->
      </div>

      <div class="about-container">
        <div class="section">
          <h2>公司介绍</h2>
          <p>
            作为AWS的官方合作伙伴，我们与AWS紧密协作，共享其全球领先的云计算技术与资源。我们深入理解AWS的各项服务，确保我们能够为客户提供最符合其业务需求的云计算方案。我们利用AWS的广泛网络覆盖、强大的数据处理能力和高度灵活的配置选项，帮助客户轻松构建、部署和管理其应用程序与数据。
          </p>

          <p>
            在成为AWS官方授权的代理商过程中，光辉云不仅通过了严格的技术认证与培训，还建立了完善的服务流程与管理体系。我们拥有一支由AWS认证专家组成的团队，他们具备丰富的实战经验与深厚的专业知识，能够为客户提供从咨询、设计、迁移到运维的全方位服务。我们始终以客户为中心，倾听客户需求，理解客户挑战，为客户提供量身定制的云计算解决方案。
          </p>

          <p>
            作为AWS官方授权的代理商，我们承诺为客户提供以下优势：
          </p>
          <ul>
            <li>官方认证与保障：我们拥有AWS官方授予的代理商资格，确保服务的正规性与专业性。</li>
            <li>技术领先与创新：我们紧跟AWS的技术步伐，确保为客户提供最前沿的云计算技术与服务。</li>
            <li>定制化解决方案：我们根据客户的特定需求与业务场景，提供高度定制化的云计算解决方案。</li>
            <li>全方位技术支持：我们提供24/7全天候的技术支持服务，确保客户在遇到问题时能够得到及时响应与解决。</li>
          </ul>
          <p>未来，光辉云将继续秉承“以客户为中心”的服务理念，依托AWS强大的云计算技术，为更多企业和开发者提供卓越的云计算服务。</p>
        </div>
  

        <div class="section">
          <h2>联系方式</h2>
          <p><strong>电子邮件：</strong>emogocoyu831@gmail.com</p>
          <p><strong>联系电话：</strong> +86 15014044754 </p>
        </div>

        <div class="section">
          <h2>企业文化</h2>
          <div class="grid-container">
            <div class="grid-item">
              <div class="icon"><img style="width: 100%;" src="../assets/icons/about-1.png"></div>
              <h3>客户至上</h3>
              <p>
                我们始终把客户的需求放在首位，努力提供高质量的服务，确保客户的满意度和信任。
              </p>
            </div>
            <div class="grid-item">
              <div class="icon"><img style="width: 100%;" src="../assets/icons/about-2.png"></div>
              <h3>合作共赢</h3>
              <p>
                强调与客户和合作伙伴建立长期、互利的关系，通过共同努力实现业务的可持续发展。
              </p>
            </div>
            <div class="grid-item">
              <div class="icon"><img style="width: 100%;" src="../assets/icons/about-3.png"></div>
              <h3>持续创新</h3>
              <p>
                鼓励团队不断学习和探索新的技术与解决方案，以适应市场变化，推动业务创新。
              </p>
            </div>
            <div class="grid-item">
              <div class="icon"><img style="width: 100%;" src="../assets/icons/about-4.png"></div>
              <h3>专业精神</h3>
              <p>
                我们的团队由经验丰富的专业人士组成，注重技术能力和服务质量，致力于为客户提供卓越的支持。
              </p>
            </div>
            <div class="grid-item">
              <div class="icon"><img style="width: 100%;" src="../assets/icons/about-5.png"></div>
              <h3>诚信透明</h3>
              <p>
                在与客户、合作伙伴及员工的互动中，我们坚持诚实和透明的原则，建立信任和良好的沟通。
              </p>
            </div>
            <div class="grid-item">
              <div class="icon"><img style="width: 100%;" src="../assets/icons/about-6.png"></div>
              <h3>团队协作</h3>
              <p>
                倡导开放的沟通与合作文化，鼓励团队成员之间相互支持，共同解决问题，提升工作效率。
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- 我们的优势 部分 -->
      <div class="adv-container">
        <h2 class="title">我们的优势</h2>
        <div class="features">
          <div
            class="adv-feature"
            v-for="(advantages, index) in advantages"
            :key="index"
          >
            <div class="feature-icon">
              <img :src="advantages.icon" />
            </div>
            <div class="adv-feature-title">{{ advantages.title }}</div>
            <div class="feature-description">
              {{ advantages.description }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <br />
    <br />
    <br />
    <br />

    <footer class="footer-section">
      <div class="footer-container">
        <!-- 左侧部分，品牌标识和介绍 -->
        <div class="footer-left">
          <img class="footer-logo" src="@/assets/logo.png" alt="光辉 云 Logo" />
          <p class="footer-description">
            关于 光辉云
            我们的使命和愿景是开发最安全、更高效的云资源。我们很自豪能够提供最好的云计算产品和解决方案。
          </p>
          <p class="footer-email">电子邮件：emogocoyu831@gmail.com</p>
          <p class="footer-copyright">
            <a href="/PrivacyPolicy">隐私政策 | 网站条款 © 2023, 光辉 云, Inc.</a>
            或其关联公司。保留所有权利。
          </p>
        </div>

        <!-- 右侧部分，友情链接和社交图标 -->
        <div class="footer-right">
          <!-- <div class="social-icons">
              <a href="#"><img src="@/assets/logo.png" alt="Telegram" /></a>
              <a href="#"><img src="@/assets/logo.png" alt="WhatsApp" /></a>
            </div> -->
        </div>
      </div>
    </footer>
  </div>
</template>
    
    <script>
export default {
  data() {
    return {
      advantages: [
        {
          title: "优质技术支持",
          description:
            "光辉云结合AWS的强大支持体系，提供24/7全天候专业技术支持，帮助客户迅速解决问题，确保业务稳定运行。",
          icon: require("@/assets/icons/contactus-1.png"), // 确保替换为实际的图标路径
        },
        {
          title: "自动化故障转移",
          description:
            " 借助AWS的故障转移机制（如跨区域复制和容灾方案），确保即使在意外发生时，服务也能快速恢复，保持业务连续性。",
          icon: require("@/assets/icons/contactus-2.png"), // 确保替换为实际的图标路径
        },
        {
          title: "可靠防护",
          description:
            " 光辉云通过AWS云服务器提供多层次的安全防护，包括数据加密、访问控制、DDoS防护等，确保业务数据的高度安全性。",
          icon: require("@/assets/icons/contactus-3.png"), // 确保替换为实际的图标路径
        },
        {
          title: "高度安全的云架构",
          description:
            " AWS通过加密技术、多因子身份验证和虚拟私有云（VPC）等安全手段，为客户的数据提供端到端保护，光辉云可为客户设计安全性极高的云架构。",
          icon: require("@/assets/icons/contactus-4.png"), // 确保替换为实际的图标路径
        },
        {
          title: "按需付费，优化成本",
          description:
            "企业只需为消耗的计算、存储和网络资源支付费用。光辉云可帮助客户优化资源分配和使用，利用如“预留实例”和“节省计划”等策略，进一步降低长期成本。",
          icon: require("@/assets/icons/contactus-5.png"), // 确保替换为实际的图标路径
        },
        {
          title: "数据分析与大数据支持",
          description:
            "AWS提供多种大数据处理服务，如Amazon Redshift、EMR和Kinesis，帮助企业快速处理和分析海量数据，洞察商业机会。",
          icon: require("@/assets/icons/contactus-6.png"), // 确保替换为实际的图标路径
        },
      ],
    };
  },
};
</script>
    
  <style>
.main {
  font-family: "Arial", sans-serif;
  padding: 0;
  margin: 0 auto;
}

.container {
  font-family: sans-serif;
}

.contact-us-section {
  background-color: #f8e9e4;
  padding: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; /* 用于定位浮动图标 */
}

.contact-us-content {
  flex: 1;
}

/* .contact-us-image {
      flex: 1;

    } */

/* .icon {
  width: 40px;
  height: 40px;
  background-color: #ccc;
  border-radius: 50%;
  margin-bottom: 10px;
} */

/* ...其他样式... */

.adv-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  .title {
  text-align: center;
  margin-bottom: 40px;
  color: #333;
}

.features {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

.adv-feature {
  border: 1px solid #eee;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.2);
  padding: 20px;
  text-align: center;
}

.feature-icon {
  width: 60px;
  height: 60px;
  margin: 0 auto 20px;
}

.feature-icon img {
  width: 100%;
  height: 100%;
}

.adv-feature-title {
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.feature-description {
  color: #666;
  line-height: 1.5;
}
}



.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px; /* 增加 padding */
  h2 {
    border-left: 5px solid #007bff;
    padding-left: 20px;
    color: #333;
    margin-bottom: 20px; /* 增加 margin */
    font-size: 24px; /* 增加字号 */
    font-weight: bold; /* 加粗 */
  }

  p {
    line-height: 1.6;
    color: #555;
    font-size: 16px; /* 增加字号 */
  }

  .section {
    margin-top: 60px; /* 增加 margin */
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 40px; /* 增加间距 */
  }

  .grid-item {
    border: 1px solid #ddd;
    padding: 30px; /* 增加 padding */
    border-radius: 10px; /* 增加圆角 */
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 添加阴影 */
    transition: transform 0.3s; /* 添加 hover 效果 */
  }

  .grid-item:hover {
    transform: translateY(-5px); /* hover 时向上移动 */
  }

  .grid-item h3 {
    margin-top: 0;
    color: #333;
    font-size: 20px; /* 增加字号 */
    font-weight: bold; /* 加粗 */
  }

  .grid-item .icon {
    width: 75px;
    margin: 0 auto;
    /* font-size: 60px; 
    color: #666; */
    margin-bottom: 40px;
  }
}

.footer-section {
  background-color: #1c1c1c;
  color: #ffffff;
  padding: 20px 0;

  .footer-container {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .footer-left {
    max-width: 50%;

    @media (max-width: 768px) {
      max-width: 100%;
      text-align: center;
    }

    .footer-logo {
      width: 65px;
      margin-bottom: 10px;
    }

    .footer-description {
      font-size: 14px;
      margin-bottom: 10px;
      line-height: 1.6;
    }

    .footer-email {
      font-size: 14px;
      margin-bottom: 20px;
    }

    .footer-copyright {
      font-size: 12px;
      color: #888888;
    }
  }

  .footer-right {
    max-width: 40%;
    text-align: right;

    @media (max-width: 768px) {
      max-width: 100%;
      text-align: center;
      margin-top: 20px;
    }

    h4 {
      font-size: 16px;
      margin-bottom: 10px;
    }

    .footer-links {
      list-style: none;
      padding: 0;
      margin: 0 0 20px;

      li {
        margin-bottom: 5px;

        a {
          color: #ffffff;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .social-icons {
      display: flex;
      justify-content: flex-end;

      @media (max-width: 768px) {
        justify-content: center;
      }

      a {
        display: inline-block;
        margin-left: 10px;

        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}
</style>
    