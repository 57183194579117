<template >
    <div >
      <div class="cu-banner">
        <div class="cu-banner-content">
          <div class="left">
            <div class="title-a">联系我们</div>
            <div class="title-a1"><strong>电子邮件：</strong>emogocoyu831@gmail.com</div>
            <div class="title-a1"><strong>电话： </strong>+86 15014044754 </div>
            <div class="title-a2">(工作日：0.00 - 24.00)</div>
            <div class="title-a2">(节假日：0.00 - 24.00)</div>
            <div class="title-a3">
              光辉云致力于提供高效、安全和可扩展的云服务，助力企业实现数字化转型与业务创新。
            </div>
            <!-- <div class="title-a4">致力于云服务，云计算等信息技术领域，您的强力伙伴</div> -->
          </div>
          <div class="right">
            <div class="title">隐私政策</div>
            <div class="title-2"></div>
            <div class="title-3">PRIVACY</div>
            <a href="contactus" style="text-decoration: none;"><div class="btn">联系我们</div></a>
          </div>
        </div>
      </div>
      <div class="cu-from">
        <div class="title">隐私政策！</div>
        <div class="purpleTriangle"></div>
        <div class="cu-from-box">
          <p class="p-all">
            光辉云（以下简称“光辉云”、“我们的”、“我们”）提供基于云的软件服务（“云服务”）和基于技术的产品（“技术产品”）。我们的技术产品可能会收集您的个人信息，以便我们为您提供更好的服务。
  
  <br><br>我们知道您的隐私对您来说很重要。我们制定本隐私政策是为了让您了解我们收集哪些个人信息、我们如何使用和共享我们收集的个人信息以及我们如何保护您的隐私。本隐私政策适用于您对本网站（“网站”）和所有产品的使用，但有单独隐私政策的产品除外。
  <br><br>
  <span class="p-title">您的同意</span>
  <br>
  在使用本网站和我们的产品之前，请仔细阅读本隐私政策。使用本网站或我们的产品，即表示您同意本隐私政策中规定的收集、使用和披露您的个人信息。如果您不同意本隐私政策的任何条款，则您不得访问或使用本网站或我们的产品。
  
  <br><br>
  <span class="p-title">我们可能收集的信息</span>
  <br>
  当您向 光辉云 提交个人信息时，您理解并同意该信息可能会跨国界传输，并可能在 光辉云 及其关联公司和子公司设有办事处或网络托管服务器的任何国家/地区存储和处理。您还承认，在某些国家/地区或针对某些活动，您的信息的收集、传输、存储和处理可能由 光辉云 的受信任供应商进行。此类供应商受合同约束，不得将您的个人信息用于自己的目的或提供给任何第三方。
  
  <br><br>
  <span class="p-title">我们收集的个人信息类型</span>
  <br>
  根据您使用的产品，我们会从您或关于您收集不同类型的个人信息。
  
  <br><br>
  您自愿提供的信息： 当您使用本网站或我们的服务时，我们会收集您和其他人有意和自愿提供的个人信息。例如，当您订阅我们的电子邮件、订阅云服务、请求技术支持、参加网络研讨会或联系我们询问问题时，我们会收集您提供的个人信息。
  
  <br><br>
  来自您的浏览器、设备或产品的信息： 我们收集您的 Web 浏览器或移动设备自动发送给我们的信息，或者如果适用，收集与我们的云服务结合使用的产品发送给我们的信息。这些信息通常包括您的 IP 地址、产品的 IP 地址、产品的 GPS 位置、操作系统名称、浏览器名称和版本以及使用信息。我们收到的信息可能取决于您的浏览器或设备设置以及您在云数据流中个人定义的信息。我们从您的 Web 浏览器和设备收到的信息本身并不能识别个人身份。通常，我们会汇总使用这些信息来帮助我们改进本网站，使其与访问者使用的技术更加兼容。但是，我们可能会将其与其他信息结合起来以尝试识别您的身份，或者我们可能会将其与可以识别您身份的信息结合起来。我们还可能出于安全目的检查我们的服务器日志（例如，检测对我们网络的入侵），并且我们可能会与适当的调查机构共享包含访问者 IP 地址的服务器日志，这些调查机构可以使用这些信息来追踪和识别您。
  
  <br><br>
  您的位置： 使用 GeoIP 技术，并经您选择同意，我们还可以从您的移动设备收集精确的实时位置信息，并使用该信息通过本网站提供定制内容或确认您确实位于您所说的位置。您的移动设备可能允许您调整设置，以使任何移动网站或应用程序都无法获取位置信息。如果您对移动或平板设备的安全和隐私设置有疑问，请参阅您的移动服务提供商或设备制造商的说明。
  
  <br><br>
  有关付款的信息： 如果您进行购买，我们会收集与购买相关的个人信息。此类信息包括付款信息，例如您的信用卡或借记卡号和其他卡信息；其他帐户和身份验证信息；以及账单、送货和联系方式。
  
  <br><br>
  来自第三方的信息： 我们可能会从第三方收集信息，包括业务合作伙伴和营销机构。这包括当我们进行联合营销或联合品牌活动时从营销合作伙伴处获取的您的联系信息。
  <br><br>
  通过 Cookie 和其他技术收集的信息： 我们使用“Cookie”和其他技术来收集信息并支持本网站的某些功能。您可以使用浏览器或设备设置删除或拒绝 Cookie，但在某些情况下，这样做可能会影响您有效使用我们的产品和服务的能力。请阅读我们的 Cookie 政策，了解有关我们如何使用 Cookie 以及您拥有的选择的更多信息。
  
  <br><br>
  <span class="p-title">信息的使用</span>
  <br>
  光辉云 使用您的信息的方式与本隐私政策一致。当您向 光辉云 提交个人信息时，您承认并同意 光辉云 可以共享、保留和处理您的个人信息。如果您不同意此类操作，请不要向 光辉云 提交个人信息。 光辉云 可能会以以下方式使用您的信息：
  <ul>
    <li>协助我们的服务并满足您的要求。例如，我们可能会通过电子邮件或其他方式与您联系，向您提供有关您帐户状态的信息、向您收取我们的服务费用、在有安全更新可用时通知您、询问我们的服务或邀请您参与调查。</li>
    <li>管理、测试、分析和改进我们的服务并促进营销，包括向您提供有关可用产品和服务的信息。如果我们这样做，我们发送给您的每封通信都将包含允许您“选择退出”接收未来通信的说明。除非您选择退出，否则您的个人信息还可能用于让您了解 光辉云 的新产品、产品升级、特别优惠和其他产品和服务（如果您选择接收此类通信）。</li>
    <li>管理我们与客户、经销商、分销商、供应商、服务提供商和附属公司的现有和潜在关系。</li>
    <li>诊断产品问题并提供其他客户关怀和支持服务。</li>
    <li>开发分析功能使我们能够改善业务运营、做出明智的决策并报告业务绩效。</li>
    <li>代表其他外部业务合作伙伴就您可能感兴趣的特定优惠与您联系。如果您同意或对所展示的优惠感兴趣，则当时可能会与第三方共享特定的可识别信息，例如姓名、电子邮件地址和/或电话号码。</li>
    <li>收集广泛的人口统计和统计信息以及其他去识别或汇总信息，光辉云 可能将这些信息用于了解广泛的人口趋势和统计信息等目的；</li>
    <li>遵守适用的法律要求、行业标准和 光辉云 的程序和政策。</li>
    <li>用于我们通知并获得客户和用户明确同意的任何其他目的。</li>
    <li>我们承诺，除非法律允许或要求，否则我们保留个人信息的时间不会超过实现收集信息的目的所需的时间。</li>
  </ul>
  <br><br>
  <span class="p-title">信息共享和披露</span><br>
  由于有关我们现有和潜在客户的信息是我们最重要的商业资产之一，因此我们力求保护这些信息并对其保密。 光辉云 会在征得您同意的情况下或在必要时共享您的个人信息，以完成任何交易或提供您请求或授权的任何产品。 光辉云 不会向其他人或非关联公司出租、出售或共享您的个人信息，除非是为了提供您请求的产品或服务，或者当我们获得您的许可或在以下情况下：
  <br><br>
  <ul>
      <li>光辉云 使用第三方服务，例如电子邮件服务提供商、语音和消息应用程序、采购、运输和订单处理商家以及代表 光辉云 充当独立承包商的营销公司。除向 光辉云 提供这些服务外，所有此类第三方均不得使用您的个人信息，并且他们必须对您的信息保密。</li>
      <li>我们还可能与我们的代理商、外部供应商或服务提供商共享您的信息，以代表我们执行职能，例如使我们能够提供我们的服务、向您提供您可能感兴趣的 光辉云 产品和服务信息，以及分析和维护 光辉云 网络。 光辉云 将仅发布提供与第三方供应商签约的服务所需的信息。 这些供应商必须对信息保持严格的安全性和保密性，并且禁止将其用于除 光辉云 的指示和政策之外的任何其他目的。</li>
      <li>如果 光辉云 涉及合并、收购、融资尽职调查、重组、破产、接管、出售公司资产或将服务转给其他提供商，您的信息可能会在法律和/或合同允许的范围内作为此类交易的一部分出售或转让。我们无法控制此类实体如何使用或披露此类信息。</li>
      <li>光辉云 保留披露商业和个人信息的权利，以响应法律要求或执行或保护 光辉云 及其合作伙伴的权利。 光辉云 仅在法律要求或善意认为有必要时才会在未经通知的情况下披露您的个人信息：</li>
    </ul>
  <br>
  – 遵守有效的法律程序，包括传票、法院命令或搜查令，以及法律另行授权的情况；在涉及任何人死亡或严重人身伤害的危险或其他紧急情况的情况下；
  
  <br><br>
  – 保护我们的权利或财产，或我们的客户或员工的安全；
  <br>
  – 防止欺诈、恶意、滥用、未经授权或非法使用或订阅我们的服务；
  
  <br><br>
  – 在法院、行政诉讼程序和其他场合提出或辩护投诉或法律索赔；
  
  <br><br>
  – 向您授权验证您帐户信息的第三方；
  <br>
  – 向外部审计师和监管机构。
  
  <br><br>
  <span class="p-title">保留您的信息</span>
  
  <br>
  我们保留您的信息的时间不会超过实现最初收集信息的目的所需的时间，除非法律出于法律、税收或监管原因或其他合法及合法的商业目的要求或允许更长的保留期。
  <br>
  <br><span class="p-title">您</span>
  <br>
  您可选择是否披露个人信息，该选择不会妨碍您使用我们的网站。但请注意，如果您选择隐瞒所要求的信息，我们可能无法为您提供某些依赖于信息收集的服务，您
  将有机会“选择加入”并针对任何可选项目做出偏好选择，而这些项目并非我们提供此类服务的先决条件。
  
  <br><br>
  <span class="p-title">安全性</span>
  <br>
  光辉云 采取了一系列措施来帮助保护客户和其他人的个人信息。
  <br>
  此外，光辉云 还实施了严格的操作准则，以确保保护订户的隐私。随着新技术的出现，光辉云 将继续修改政策并实施额外的安全功能。
  但是，没有一种安全措施是完美的。这意味着 光辉云 无法保证个人信息 100% 的安全。最终，您有责任维护您拥有的身份、密码和/或任何其他个人信息的保密性。我们对他人对您提供的任何信息的使用不承担任何责任，也无法控制他人对其的使用，您在选择提交给第三方的个人信息时应谨慎行事。同样，我们对您通过服务从其他用户收到的个人信息或其他信息的内容也不承担任何责任。我们无法保证也不承担验证第三方提供的个人信息或其他信息的准确性的责任。
  
  <br><br>
  <span class="p-title">儿童隐私</span>
  
  <br>
  我们不会故意收集、保留或使用 13 岁以下儿童的个人信息。如果您未满 13 岁，请不要通过我们的服务提交任何个人信息。如果您有理由相信 13 岁以下的儿童通过我们的服务向 光辉云 提供了个人信息，请联系我们，我们将努力从我们的数据库中删除该信息。
  
  <br><br>
  <span class="p-title">本隐私政策的变更</span>
  <br>
  光辉云 保留不时更新和/或更改本隐私政策条款的权利。如果我们对您的信息的使用方式做出任何更改，我们将在此网站上发布政策变更供您参考。我们鼓励您定期查看我们的隐私政策，以便您始终了解我们收集的信息类型、信息使用方式以及我们在何种情况下（如果有）披露此类信息。
  
  <br><br>
  <span class="p-title">风险和免责声明</span>
  <br>
  如果您在网上发布可供公众访问的个人信息，您可能会因此收到来自其他方的未经请求的消息。虽然我们努力保护您的信息，但 光辉云 无法确保或保证您传输给我们的任何信息的安全性，本隐私政策明确涵盖的信息除外。
  光辉云 的网站可能包含第三方网站的链接。访问链接到我们网站的任何其他互联网网站均由用户自行承担风险，我们对此类其他网站的任何信息、数据、意见、建议、声明、使用条款或隐私惯例的准确性或可靠性概不负责，除非该网站也是我们拥有或控制的网站。
  <br><br>
  <span class="p-title">如果您认为我们没有遵守本隐私政策，您应立即<span class="p-this"><router-link to="/contactus" > 在此处 </router-link></span>联系我们</span>
  
          </p>
  
  
        </div>
      </div>

      <footer class="footer-section">
      <div class="footer-container">
        <!-- 左侧部分，品牌标识和介绍 -->
        <div class="footer-left">
          <img class="footer-logo" src="@/assets/logo.png" alt="光辉 云 Logo" />
          <p class="footer-description">
            关于 光辉云
            我们的使命和愿景是开发最安全、更高效的云资源。我们很自豪能够提供最好的云计算产品和解决方案。
          </p>
          <p class="footer-email">电子邮件：emogocoyu831@gmail.com</p>
          <p class="footer-copyright">
            <a href="/PrivacyPolicy">隐私政策 | 网站条款 © 2023, 光辉 云, Inc.</a>
            或其关联公司。保留所有权利。
          </p>
        </div>

        <!-- 右侧部分，友情链接和社交图标 -->
        <div class="footer-right">
          <!-- <div class="social-icons">
            <a href="#"><img src="@/assets/logo.png" alt="Telegram" /></a>
            <a href="#"><img src="@/assets/logo.png" alt="WhatsApp" /></a>
          </div> -->
        </div>
      </div>
    </footer>
    </div>
  </template>
  
  <script>
export default {
  data() {
    return {
      options: [
        {
          value: 1,
          label: "telegram",
        },
        {
          value: 2,
          label: "微信号",
        },
        {
          value: "3",
          label: "手机号",
        },
        {
          value: 4,
          label: "Whatsapp",
        },
      ],
      value: "",
      numberValidateForm: {
        name: "",
        email: "",
        neirong: "",
      },
    };
  },
};
</script>
  
  <style lang="scss" scoped>
@media screen and (max-width: 1100px) {
  .cu-banner {
    background-color: #141719;
    min-height: 400px;
    .cu-banner-content {
      width: 70%;
      margin: 0 auto;
      .left {
        float: left;
        margin-top: 4%;
        display: none;
        .title-a {
          color: #bababa;
          font-family: DingTalk JinBuTi;
        }
        .title-a1 {
          color: #fff;
          font-size: 20px;
          font-weight: 600;
          font-family: DingTalk JinBuTi;
          margin-top: 10px;
        }
        .title-a2 {
          color: #fff;
          margin-top: 10px;
        }
        .title-a3 {
          color: #bababa;
          font-weight: 600;
          margin-top: 120px;
          width: 400px;
          line-height: 1.5;
        }
      }
      .right {
        float: right;
        margin-top: 20%;
        .title {
          color: #fff;
          font-size: 50px;
          font-weight: 600;
        }
        .title-2 {
          color: #fff;
          text-align: right;
          font-size: 18px;
        }
        .title-3 {
          color: #7171cb;
          font-size: 60px;
          font-weight: 700;
        }
        .btn {
          width: 100px;
          height: 40px;
          background-color: #6262b3;
          text-align: center;
          color: #343462;
          line-height: 40px;
          font-family: DingTalk JinBuTi;
          cursor: pointer;
        }
        .btn:hover {
          color: #fff;
          box-shadow: 8px 8px 20px 0 rgba(0, 98, 255, 0.5);
        }
      }
    }
  }
  .cu-from {
    padding-top: 50px;
    min-height: 700px;
    .title {
      color: #6262b3;
      font-size: 30px;
      font-weight: 700;
      text-align: center;
    }
    .purpleTriangle {
      width: 0px;
      height: 0px;
      border: 20px solid transparent;
      border-top-color: #6262b3;
      margin: 0 auto;
    }
    .cu-from-box {
      display: flex;
      justify-content: center;
      width: 100%;
      .p-all {
        width: 70%;
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 70px;
      }
      .p-title {
        font-weight: bold;
      }
      ul {
        list-style-type: disc; /* 确保使用小黑点 */
        padding-left: 20px; /* 留出空间的大小，根据需要调整 */
        line-height: 20px;
      }
      li {
        margin: 5px;
      }
      .p-this {
        font-size: 15px;
        font-style: italic;
        color: #2b70ca;
      }
    }
  }
  .footer-section {
    background-color: #1c1c1c;
    color: #ffffff;
    padding: 20px 0;

    .footer-container {
      display: flex;
      justify-content: space-between;
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 20px;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
    }

    .footer-left {
      max-width: 50%;

      @media (max-width: 768px) {
        max-width: 100%;
        text-align: center;
      }

      .footer-logo {
        width: 65px;
        margin-bottom: 10px;
      }

      .footer-description {
        font-size: 14px;
        margin-bottom: 10px;
        line-height: 1.6;
      }

      .footer-email {
        font-size: 14px;
        margin-bottom: 20px;
      }

      .footer-copyright {
        font-size: 12px;
        color: #888888;
      }
    }

    .footer-right {
      max-width: 40%;
      text-align: right;

      @media (max-width: 768px) {
        max-width: 100%;
        text-align: center;
        margin-top: 20px;
      }

      h4 {
        font-size: 16px;
        margin-bottom: 10px;
      }

      .footer-links {
        list-style: none;
        padding: 0;
        margin: 0 0 20px;

        li {
          margin-bottom: 5px;

          a {
            color: #ffffff;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .social-icons {
        display: flex;
        justify-content: flex-end;

        @media (max-width: 768px) {
          justify-content: center;
        }

        a {
          display: inline-block;
          margin-left: 10px;

          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1100px) {
  .cu-banner {
    background-color: #141719;
    min-height: 400px;
    .cu-banner-content {
      width: 70%;
      margin: 0 auto;
      .left {
        float: left;
        margin-top: 4%;
        .title-a {
          color: #bababa;
          font-family: DingTalk JinBuTi;
        }
        .title-a1 {
          color: #fff;
          font-size: 20px;
          font-weight: 600;
          font-family: DingTalk JinBuTi;
          margin-top: 10px;
        }
        .title-a2 {
          color: #fff;
          margin-top: 10px;
        }
        .title-a3 {
          color: #bababa;
          font-weight: 600;
          margin-top: 120px;
          width: 400px;
          line-height: 1.5;
        }
      }
      .right {
        float: right;
        margin-top: 7%;
        .title {
          color: #fff;
          font-size: 60px;
          font-weight: 600;
        }
        .title-2 {
          color: #fff;
          text-align: right;
          font-size: 18px;
        }
        .title-3 {
          color: #7171cb;
          font-size: 80px;
          font-weight: 700;
        }
        .btn {
          width: 100px;
          height: 40px;
          background-color: #6262b3;
          text-align: center;
          color: #343462;
          line-height: 40px;
          font-family: DingTalk JinBuTi;
          cursor: pointer;
        }
        .btn:hover {
          color: #fff;
          box-shadow: 8px 8px 20px 0 rgba(0, 98, 255, 0.5);
        }
      }
    }
  }
  .cu-from {
    padding-top: 50px;
    min-height: 700px;
    .title {
      color: #6262b3;
      font-size: 30px;
      font-weight: 700;
      text-align: center;
    }
    .purpleTriangle {
      width: 0px;
      height: 0px;
      border: 20px solid transparent;
      border-top-color: #6262b3;
      margin: 0 auto;
    }
    .cu-from-box {
      display: flex;
      justify-content: center;
      width: 100%;
      .p-all {
        width: 70%;
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 70px;
        text-align: left;
      }
      .p-title {
        font-weight: bold;
      }
      ul {
        list-style-type: disc; /* 确保使用小黑点 */
        padding-left: 20px; /* 留出空间的大小，根据需要调整 */
        line-height: 20px;
      }
      li {
        margin: 5px;
      }
      .p-this {
        font-size: 15px;
        font-style: italic;
        color: #2b70ca;
      }
    }
  }
}
</style>
  