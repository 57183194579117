<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-10-12 16:38:56
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-12-09 10:16:33
 * @FilePath: \cloud\src\views\Home.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->

<template>
  <div>
    <div class="main" v-if="showHomeAndroid == false">


      <div class="banner">
        <!-- 将 interval 设置为 1000 毫秒，表示每秒切换一张图片 -->
        <el-carousel :interval="1800" arrow="always">
          <el-carousel-item v-for="(image, index) in images" :key="index">
            <img :src="image" alt="banner image" class="carousel-image" />
            <!-- 文字和按钮 -->
            <div class="banner-content">
              <h1>DHS Daily Health<br>Supplement</h1>
              <p>Your trusted partner for family health supplements</p>
              <!-- 添加点击事件，滚动到 products 部分 -->
              <a @click.prevent="scrollToProducts" class="btn">VIEW PRODUCTS</a>
            </div>
          </el-carousel-item>
        </el-carousel>

      </div>








      <div class="cent" id="about">
        <div class="health-supplement">
          <div class="content" v-animate-on-scroll>
            <h4 class="tagline">QUALITY HEALTH SUPPLEMENTS</h4>
            <h2 class="title">Crafted by expert pharmacists</h2>
            <p class="description">
              DHS NATURE is a trusted brand rooted in a strong commitment to natural health and wellness. They aim to
              harness the power of nature’s ingredients to create products that promote overall well-being. With
              rigorous testing processes and a genuine passion for holistic wellness, it’s a go-to brand for quality
              vitamins.
            </p>
            <a href="#contact" class="contact-link" style="text-decoration: underline">GET IN TOUCH</a>
          </div>
          <div class="image-container" v-animate-on-scroll>
            <img src="@/assets/home/banner.jpg" alt="Health Supplements" />
          </div>
        </div>
      </div>













      <div class="cent back" id="products">
        <div class="products-main" v-animate-on-scroll>
          <h4 class="tagline">Explore our products</h4>
          <h2 class="title">Tailored for your health needs</h2>
          <div class="card-grid">
            <!-- 动态生成产品卡片 -->
            <div class="card" v-for="(product, index) in products" :key="index">
              <div class="card-image">
                <img :src="product.image" alt="product image">
              </div>
              <!-- 使用 product.link 生成不同的产品链接 -->
              <a :href="product.link" class="card-link">
                <div class="card-content">
                  <!-- <h5>{{ product.title }}</h5> -->
                  <p>View Details &gt;&gt;&gt;</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>





      <div class="cent" id="contact">
        <div class="contact-section" style="width: 53%;background-color: white;" v-animate-on-scroll>
          <div class="contact-left ">
            <h3>GET IN TOUCH</h3>
            <h2>We're here to help</h2>
          </div>
          <div class="contact-right">
            <div class="contact-info">
              <h4>GET IN TOUCH</h4>
              <p>

                <a href="mailto:fan13505547570@163.com"
                  style="text-decoration: underline; display: inline-flex; align-items: center;">
                  <svg t="1730308728745" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="4280" width="20" height="20"
                    style="vertical-align: middle; margin-right: 5px;">
                    <path
                      d="M893.842 217.557H130.159c-16.261 0-29.443 13.182-29.443 29.443v530c0 16.261 13.182 29.443 29.443 29.443h763.683c16.261 0 29.443-13.183 29.443-29.443V247c0-16.261-13.182-29.443-29.443-29.443z m-85.584 58.886L512 507.651 215.742 276.443h592.516zM159.602 747.557v-440.23l334.283 260.885A29.4 29.4 0 0 0 512 574.443a29.4 29.4 0 0 0 18.115-6.231l334.283-260.884v440.229H159.602z"
                      fill="" p-id="4281"></path>
                  </svg>
                  <span style="margin-left: 10px;">fan13505547570@163.com</span>
                </a>
              </p>
            </div>
            <div class="contact-info">
              <h4>Location</h4>
              <p>
                <a href="https://www.google.com/maps/place/%E6%BE%B3%E5%A4%A7%E5%88%A9%E4%BA%9A/@-24.5310385,111.875717,4z/data=!3m1!4b1!4m6!3m5!1s0x2b2bfd076787c5df:0x538267a1955b1352!8m2!3d-25.274398!4d133.775136!16zL20vMGNoZ2h5?hl=zh-cn&entry=ttu&g_ep=EgoyMDI0MTAyNy4wIKXMDSoASAFQAw%3D%3D"
                  style="text-decoration: underline;" target="_blank">
                  <svg t="1730309449520" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="6174" width="20" height="20"
                    style="vertical-align: middle; margin-right: 5px;">
                    <path
                      d="M494.5 771.5l16.9 18.7 17.1-18.5c2.4-2.6 60.4-65.5 119.4-145.3C729 516.6 770.2 431.3 770.2 372.9c0-68.3-26.6-132.6-74.9-180.9-48.3-48.3-112.6-74.9-180.9-74.9s-132.7 26.6-181 74.9c-48.3 48.3-74.9 112.6-74.9 180.9 0 58.3 40.2 143.5 119.4 253.3 57.5 79.8 114.2 142.7 116.6 145.3zM304.4 372.9c0-115.7 94.1-209.8 209.8-209.8S724 257.2 724 372.9c0 25.5-11.5 60.3-34.1 103.2-19 36-45.5 77.3-79 122.6-38.7 52.4-77.7 98.1-99.3 122.7-21.1-24.5-59-70-96.6-122.1-32.7-45.4-58.7-86.7-77.3-122.8-22-43.1-33.3-78-33.3-103.6z"
                      p-id="6175"></path>
                    <path
                      d="M852.2 718.4c-35.6-15-85.9-27.4-145.4-36l-6.5 45.5c55.7 8 102 19.3 134.1 32.8 32.5 13.7 39.2 25.1 39.2 27.4 0 4.3-16.9 25.9-95.2 45.4-70.9 17.6-165.5 27.4-266.4 27.4-100.9 0-195.5-9.7-266.4-27.4-78.2-19.5-95.2-41.1-95.2-45.4 0-2.3 6.6-13.7 38.9-27.3 31.9-13.5 78-24.8 133.4-32.8l-6.6-45.5c-59.3 8.5-109.3 21-144.7 35.9-44.5 18.8-67 42.2-67 69.7 0 21.6 14 40.9 41.6 57.1 21.3 12.5 51.1 23.6 88.5 32.9 74.4 18.5 172.9 28.7 277.5 28.7s203.1-10.2 277.5-28.7c37.5-9.3 67.2-20.4 88.5-32.9 27.6-16.2 41.6-35.4 41.6-57.1 0-27.4-22.7-50.9-67.4-69.7zM511.4 459.5c48 0 87-39 87-87s-39-87-87-87-87 39-87 87 39.1 87 87 87z m0-128c22.6 0 41 18.4 41 41s-18.4 41-41 41-41-18.4-41-41 18.4-41 41-41z"
                      p-id="6176"></path>
                  </svg>
                  <span style="margin-left: 10px;">Australia AUS</span> </a>
              </p>
            </div>
            <div class="contact-info">
              <h4>Hours</h4>
              <ul class="hours">
                <li>Monday: 9:00am - 10:00pm</li>
                <li>Tuesday: 9:00am - 10:00pm</li>
                <li>Wednesday: 9:00am - 10:00pm</li>
                <li>Thursday: 9:00am - 10:00pm</li>
                <li>Friday: 9:00am - 10:00pm</li>
                <li>Saturday: 9:00am - 6:00pm</li>
                <li>Sunday: 9:00am - 12:00pm</li>
              </ul>
            </div>
          </div>
        </div>
      </div>










      <footer class="footer-section">
        <div class="footer-container">
          <!-- 左侧部分，品牌标识和介绍 -->
          <div class="footer-left">
            <img class="footer-logo" src="@/assets/logo.png" alt="光辉 云 Logo" />
            <p class="footer-description">
              关于 光辉云
              我们的使命和愿景是开发最安全、更高效的云资源。我们很自豪能够提供最好的云计算产品和解决方案。
            </p>
            <p class="footer-email">电子邮件：emogocoyu831@gmail.com</p>
            <p class="footer-copyright">
              <a href="/PrivacyPolicy">隐私政策 | 网站条款 © 2023, 光辉 云, Inc.</a>
              或其关联公司。保留所有权利。
            </p>
          </div>

          <!-- 右侧部分，友情链接和社交图标 -->
          <div class="footer-right">
            <!-- <div class="social-icons">
        <a href="#"><img src="@/assets/logo.png" alt="Telegram" /></a>
        <a href="#"><img src="@/assets/logo.png" alt="WhatsApp" /></a>
      </div> -->
          </div>
        </div>
      </footer>








    </div>

    <div class="main" v-if="showHomeAndroid == true">


      <div class="banner">
        <!-- 将 interval 设置为 1000 毫秒，表示每秒切换一张图片 -->
        <el-carousel :interval="1800" arrow="always">
          <el-carousel-item v-for="(image, index) in imagesIPHONE" :key="index">
            <img :src="image" alt="banner image" class="carousel-image" style="width: 100%;height: 650px;" />
            <!-- 文字和按钮 -->
            <div class="banner-content">
              <h1 style="width: 300px;">DHS Daily Health<br>Supplement</h1>
              <p>Your trusted partner for family health supplements</p>
              <!-- 添加点击事件，滚动到 products 部分 -->
              <a @click.prevent="scrollToProducts" class="btn">VIEW PRODUCTS</a>
            </div>
          </el-carousel-item>
        </el-carousel>

      </div>








      <div class="cent" id="about">
        <div class="health-supplement">
          <div class="content" v-animate-on-scroll>
            <h4 class="tagline">QUALITY HEALTH SUPPLEMENTS</h4>
            <h2 class="title">Crafted by expert pharmacists</h2>
            <p class="description">
              DHS NATURE is a trusted brand rooted in a strong commitment to natural health and wellness. They aim to
              harness the power of nature’s ingredients to create products that promote overall well-being. With
              rigorous testing processes and a genuine passion for holistic wellness, it’s a go-to brand for quality
              vitamins.
            </p>
            <a href="#contact" class="contact-link" style="text-decoration: underline">GET IN TOUCH</a>
          </div>
          <div class="image-container" v-animate-on-scroll>
            <img src="@/assets/home/banner.jpg" alt="Health Supplements" />
          </div>
        </div>
      </div>













      <div class="cent back" id="products">
        <div class="products-main" v-animate-on-scroll>
          <div>
            <h4 class="tagline">Explore our products</h4>
            <h2 class="title">Tailored for your health needs</h2>
            <div class="card-grid">
              <!-- 动态生成产品卡片 -->
              <div class="card" v-for="(product, index) in products" :key="index">
                <a :href="product.link" class="card-link">
                  <div class="card-image">
                    <img :src="product.image" alt="product image">
                  </div>

                  <!-- 使用 product.link 生成不同的产品链接 -->

                  <div class="card-content">
                    <!-- <h5>{{ product.title }}</h5> -->
                    <p style="text-align: center;">View Details &gt;&gt;&gt;</p>
                  </div>
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>





      <div class="cent" id="contact">
        <div class="contact-section" style="width: 53%;" v-animate-on-scroll>
          <div class="contact-left ">
            <h3 class="tagline">GET IN TOUCH</h3>
            <h2 class="title">We're here to help</h2>
          </div>
          <div class="contact-right">
            <div class="contact-info">
              <h4>GET IN TOUCH</h4>
              <p>

                <a href="mailto:fan13505547570@163.com"
                  style="text-decoration: underline; display: inline-flex; align-items: center;">
                  <svg t="1730308728745" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="4280" width="20" height="20"
                    style="vertical-align: middle; margin-right: 5px;">
                    <path
                      d="M893.842 217.557H130.159c-16.261 0-29.443 13.182-29.443 29.443v530c0 16.261 13.182 29.443 29.443 29.443h763.683c16.261 0 29.443-13.183 29.443-29.443V247c0-16.261-13.182-29.443-29.443-29.443z m-85.584 58.886L512 507.651 215.742 276.443h592.516zM159.602 747.557v-440.23l334.283 260.885A29.4 29.4 0 0 0 512 574.443a29.4 29.4 0 0 0 18.115-6.231l334.283-260.884v440.229H159.602z"
                      fill="" p-id="4281"></path>
                  </svg>
                  <span style="margin-left: 10px;">fan13505547570@163.com</span>
                </a>
              </p>
            </div>
            <div class="contact-info">
              <h4>Location</h4>
              <p>
                <a href="https://www.google.com/maps/place/%E6%BE%B3%E5%A4%A7%E5%88%A9%E4%BA%9A/@-24.5310385,111.875717,4z/data=!3m1!4b1!4m6!3m5!1s0x2b2bfd076787c5df:0x538267a1955b1352!8m2!3d-25.274398!4d133.775136!16zL20vMGNoZ2h5?hl=zh-cn&entry=ttu&g_ep=EgoyMDI0MTAyNy4wIKXMDSoASAFQAw%3D%3D"
                  style="text-decoration: underline;" target="_blank">
                  <svg t="1730309449520" class="icon" viewBox="0 0 1024 1024" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" p-id="6174" width="20" height="20"
                    style="vertical-align: middle; margin-right: 5px;">
                    <path
                      d="M494.5 771.5l16.9 18.7 17.1-18.5c2.4-2.6 60.4-65.5 119.4-145.3C729 516.6 770.2 431.3 770.2 372.9c0-68.3-26.6-132.6-74.9-180.9-48.3-48.3-112.6-74.9-180.9-74.9s-132.7 26.6-181 74.9c-48.3 48.3-74.9 112.6-74.9 180.9 0 58.3 40.2 143.5 119.4 253.3 57.5 79.8 114.2 142.7 116.6 145.3zM304.4 372.9c0-115.7 94.1-209.8 209.8-209.8S724 257.2 724 372.9c0 25.5-11.5 60.3-34.1 103.2-19 36-45.5 77.3-79 122.6-38.7 52.4-77.7 98.1-99.3 122.7-21.1-24.5-59-70-96.6-122.1-32.7-45.4-58.7-86.7-77.3-122.8-22-43.1-33.3-78-33.3-103.6z"
                      p-id="6175"></path>
                    <path
                      d="M852.2 718.4c-35.6-15-85.9-27.4-145.4-36l-6.5 45.5c55.7 8 102 19.3 134.1 32.8 32.5 13.7 39.2 25.1 39.2 27.4 0 4.3-16.9 25.9-95.2 45.4-70.9 17.6-165.5 27.4-266.4 27.4-100.9 0-195.5-9.7-266.4-27.4-78.2-19.5-95.2-41.1-95.2-45.4 0-2.3 6.6-13.7 38.9-27.3 31.9-13.5 78-24.8 133.4-32.8l-6.6-45.5c-59.3 8.5-109.3 21-144.7 35.9-44.5 18.8-67 42.2-67 69.7 0 21.6 14 40.9 41.6 57.1 21.3 12.5 51.1 23.6 88.5 32.9 74.4 18.5 172.9 28.7 277.5 28.7s203.1-10.2 277.5-28.7c37.5-9.3 67.2-20.4 88.5-32.9 27.6-16.2 41.6-35.4 41.6-57.1 0-27.4-22.7-50.9-67.4-69.7zM511.4 459.5c48 0 87-39 87-87s-39-87-87-87-87 39-87 87 39.1 87 87 87z m0-128c22.6 0 41 18.4 41 41s-18.4 41-41 41-41-18.4-41-41 18.4-41 41-41z"
                      p-id="6176"></path>
                  </svg>
                  <span style="margin-left: 10px;">Australia AUS</span> </a>
              </p>
            </div>
            <div class="contact-info">
              <h4>Hours</h4>
              <ul class="hours">
                <li>
                  <p>Mon</p>9:00am - 10:00pm
                </li>
                <li>
                  <p>Tue</p>9:00am - 10:00pm
                </li>
                <li>
                  <p>Wed</p> 9:00am - 10:00pm
                </li>
                <li>
                  <p>Thu</p> 9:00am - 10:00pm
                </li>
                <li>
                  <p>Fri</p>9:00am - 10:00pm
                </li>
                <li>
                  <p>Sat</p> 9:00am - 6:00pm
                </li>
                <li>
                  <p>Sun</p> 9:00am - 12:00pm
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>










      <footer class="footer-section">
        <div class="footer-container">
          <!-- 左侧部分，品牌标识和介绍 -->
          <div class="footer-left">
            <img class="footer-logo" src="@/assets/logo.png" alt="光辉 云 Logo" />
            <p class="footer-description">
              关于 光辉云
              我们的使命和愿景是开发最安全、更高效的云资源。我们很自豪能够提供最好的云计算产品和解决方案。
            </p>
            <p class="footer-email">电子邮件：emogocoyu831@gmail.com</p>
            <p class="footer-copyright">
              <a href="/PrivacyPolicy">隐私政策 | 网站条款 © 2023, 光辉 云, Inc.</a>
              或其关联公司。保留所有权利。
            </p>
          </div>

          <!-- 右侧部分，友情链接和社交图标 -->
          <div class="footer-right">
            <!-- <div class="social-icons">
  <a href="#"><img src="@/assets/logo.png" alt="Telegram" /></a>
  <a href="#"><img src="@/assets/logo.png" alt="WhatsApp" /></a>
</div> -->
          </div>
        </div>
      </footer>








    </div>
  </div>






















</template>

<script>
// document.getElementById("myButton").onclick = function() {
//   window.location.href = "https://t.me/aws725";
// };
export default {
  data() {
    return {

      showHomeAndroid: false,
      screenWidth: null,

      products: [
        { title: "Advanced 3 in 1 Joint care complex", image: require('@/assets/26-1.png'), link: '/products/Advanced3in1Jointcarecomplex' },
        { title: "CellRenewalNAD", image: require('@/assets/15-1.png'), link: '/products/CellRenewalNAD' },
        { title: "Colostrum tablets", image: require('@/assets/1-1.png'), link: '/products/Colostrum-tablets' },
        { title: "DHS Propolis Power capsule", image: require('@/assets/14-1.png'), link: '/products/DHS-Propolis-Power-capsule' },
        { title: "Eye Excel with Bilberry 10000mg", image: require('@/assets/17-1.png'), link: '/products/Eye-Excel-with-Bilberry-10000mg' },
        { title: "Liver Care with Milk Thistle 40000mg", image: require('@/assets/7-1.png'), link: '/products/Liver-Care-with-Milk-Thistle-40000mg' },
        { title: "Pacific all in one", image: require('@/assets/25-1.png'), link: '/products/Pacific-all-in-one' }

      ],
      images: [
        require("@/assets/banner1.jpg"),
        require("@/assets/banner2.jpg"),
        require("@/assets/banner3.jpg"),
        require("@/assets/banner4.jpg"),

      ],
      imagesIPHONE: [
        require("@/assets/iphone-banner1.jpg"),
        require("@/assets/iphone-banner2.jpg"),
        require("@/assets/iphone-banner3.jpg"),
        require("@/assets/iphone-banner4.jpg"),

      ],

    };
  },


  methods: {
    // 定义方法，点击按钮后平滑滚动到 id 为 products 的部分
    scrollToProducts() {
      const productsSection = document.getElementById('products');
      if (productsSection) {
        productsSection.scrollIntoView({ behavior: 'smooth' });
      }
    },


    bodyScale() {
      var devicewidth = document.documentElement.clientWidth; //获取当前分辨率下的可是区域宽度
      var scale = devicewidth / 1600; // 分母——设计稿的尺寸
      document.body.style.zoom = scale; //放大缩小相应倍数
    },

  },

  watch: {
    screenWidth: {
      handler: function (val) {
        if (val >= 800) {
          this.bodyScale();
          this.showHomeAndroid = false;
          console.log(this.showHomeAndroid);
        } else if (val < 800) {
          this.showHomeAndroid = true;
          console.log(this.showHomeAndroid);
        }
        this.$forceUpdate();
      },
      immediate: true,
      deep: true,
    },
  },



  mounted() {
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },

  directives: {
    animateOnScroll: {
      inserted(el) {
        function onScroll() {
          const rect = el.getBoundingClientRect();
          if (rect.top < window.innerHeight && rect.bottom > 0) {
            el.classList.add("animate-fade-in");
            window.removeEventListener("scroll", onScroll);
          }
        } ``
        window.addEventListener("scroll", onScroll);
        onScroll();
      }
    }
  }







};
</script>










<style scoped lang="scss">
/* 动画效果 */
@keyframes fadeIn {
  from {
    // opacity: 0;
    transform: translateY(200px);
    /* 向下移动20px */
  }

  to {
    opacity: 1;
    transform: translateY(0);
    /* 位置恢复 */
  }
}

.animate-fade-in {
  opacity: 0;
  /* 初始透明 */
  animation: fadeIn 1.3s forwards;
  /* 执行动画 */
}














/* 手机端样式 */
/* 手机端样式 */
@media (max-width: 768px) {

  a {
    text-decoration: none;
    color: #000;
  }

  /* 主容器 */
  .main {
    margin: 0;
    padding: 0;
  }

  /* Banner 样式 */
  .banner {
    margin-top: 80px;
  }

  .banner-image {
    height: 600px;


    /* 调整图片高度以适配小屏幕 */
  }

  .el-carousel__item {
    height: 600px;
  }

  ::v-deep(.el-carousel__container) {
    height: 600px !important;
  }

  ::v-deep.banner-content {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    text-align: left;
    /* 居中对齐文字 */

    h1 {
      font-size: 2rem;
      /* 缩小字体大小 */
      margin-bottom: 40px;
      margin-left: 270px;
      color: white;
    }

    p {
      width: 300px;
      font-size: 1rem;
      margin-bottom: 840px;
      margin-left: 270px;
      color: white;
    }

    ::v-deep(.btn) {
      font-size: 0.9rem;
      height: 40px !important;
      padding: 0 15px !important;
      margin-bottom: 40px !important;
    }
  }

  /* 健康补充部分 */
  .health-supplement {
    flex-direction: column;
    /* 改为列布局 */
    align-items: center;
    padding: 30px;
    height: auto;

    .content {
      max-width: 100%;
      padding-right: 0;

      .tagline {
        font-size: 16px;
        color: #28a745;
      }

      .title {
        font-size: 24px;
      }

      .description {
        font-size: 17px;
        line-height: 28px;
        color: #717171;
      }
    }

    .image-container {
      width: 100%;
      margin-top: 20px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  /* 产品部分 */
  .products-main {
    padding: 30px;
    background-color: #EEF1EF;

    .tagline {
      font-size: 16px;
      color: #28a745;
    }

    .title {
      font-size: 24px;
    }


    .card-grid {
      grid-template-columns: 1fr;
      /* 改为单列布局 */
      gap: 10px;
    }

    .card {
      width: 100%;
      background-color: white;
      margin-top: 20px;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      /* 初始阴影效果 */

      &:hover {
        transform: scale(1.05);
        /* 放大效果 */
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        /* 增加阴影效果 */
      }

      .card-image img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        /* 调整图片高度 */
      }

      .card-content {
        height: 35px;
      }

      .card-content p {
        font-size: 18px;
        text-align: center;
      }
    }
  }


  /* 联系方式部分 */
  .contact-section {
    flex-direction: column;
    margin: 30px;
    width: 80% !important;
    background-color: white;
    margin-bottom: 100px;
    border: 1px solid rgb(255, 255, 255);

    .contact-left,
    .contact-right {
      width: 100%;
      text-align: left;
      color: #000;

      /* 居中对齐 */
      .contact-info {

        .hours {
          list-style: none;

          li {
            p {
              width: 55px;
              display: inline-block;
              height: 0px;

            }
          }

        }
      }
    }

    .contact-left {
      margin-right: 0;
      margin-top: 50px;

      .tagline {
        font-size: 16px;
        color: #28a745;
      }

      .title {
        font-size: 24px;
      }


    }

    .contact-right {
      background-color: #e0e0e0;
      margin-top: 20px;
      padding: 15px;
      width: 90%;
    }
  }

  /* 底部样式 */
  .footer-section {
    padding: 20px;

    .footer-left,
    .footer-right {
      text-align: center;
    }

    .footer-description {
      font-size: 0.9rem;
    }

    .footer-logo {
      width: 80px;
      /* 缩小Logo大小 */
      margin-bottom: 10px;
    }
  }
}














@media (min-width: 768px) {

  .main {
    margin: 0;
    padding: 0;

  }

  .cent {
    width: 100%;
    display: flex;

    justify-content: center;
    justify-items: center;
  }

  .banner {
    margin-top: 120px;
  }



  .banner-container {
    position: relative;
    width: 100%;
  }

  .banner {
    position: relative;
  }

  .banner-image {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }

  .banner-content {
    position: absolute;
    top: 45%;
    left: 40%;
    transform: translate(-50%, -50%);
    text-align: left;
    color: white;

    h1 {
      font-size: 3.5rem;
      font-weight: bold;
      margin-bottom: 10px;
    }

    p {
      font-size: 1.5rem;
      margin-bottom: 20px;
    }

    .btn {
      background-color: #28a745;
      color: white;
      padding: 0 20px;
      text-decoration: none;
      border-radius: 5px;
      font-size: 1.0rem;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: #218838;
      }
    }
  }

  /* 假设 products 部分的样式 */
  .products-section {
    height: 600px;
    padding: 50px;
    background-color: #f4f4f4;
    text-align: center;

    h2 {
      font-size: 2.5rem;
      margin-bottom: 20px;
    }

    p {
      font-size: 1.2rem;
    }
  }





  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }



  ::v-deep .el-carousel__container {
    height: 800px;
  }



  .health-supplement {
    width: 53%;
    height: 800px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    background-color: #ffffff;

    .content {
      max-width: 50%;
      padding-right: 20px;

      .tagline {
        font-size: 14px;
        font-weight: bold;
        color: #4caf50;
        margin-bottom: 10px;
      }

      .title {
        font-size: 38px;
        font-weight: bold;
        color: #333;
        margin-bottom: 20px;
      }

      .description {
        font-size: 20px;
        color: #666;
        line-height: 1.6;
        margin-bottom: 20px;
      }

      .contact-link {
        font-size: 20px;
        color: #333;
        text-decoration: underline;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: #000;
        }
      }
    }

    .image-container {
      flex: 1;

      img {
        max-width: 100%;
        height: auto;
        border-radius: 8px;
      }
    }
  }


  .products-main {
    padding-top: 150px;
    padding-bottom: 150px;

    .tagline {
      font-size: 14px;
      font-weight: bold;
      color: #4caf50;
      margin-bottom: 10px;
    }

    .title {
      font-size: 38px;
      font-weight: bold;
      color: #333;
      margin-bottom: 20px;
    }

    .card-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;
      padding: 20px;
    }

    .card {
      background-color: #fff;
      border-radius: 0px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      transition: transform 0.3s ease, box-shadow 0.3s ease;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      }

      .card-link {
        text-decoration: none;
        /* 移除下划线 */
        color: inherit;
        /* 保持文本颜色 */
      }

      .card-link:hover {
        text-decoration: underline;
        /* 可以为悬停添加下划线，或者其他效果 */
        color: #28a745;
        /* 悬停时变成其他颜色 */
      }

      .card-image {
        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
        }
      }

      .card-content {
        height: 30px;

        // padding: 20px;
        text-align: center;

        // h5 {
        //   font-size: 1.2rem;
        //   color: #333;
        //   margin-bottom: 10px;
        // }

        p {
          font-size: 1rem;
          color: #28a745;
          font-weight: bold;
          line-height: 11px;
        }
      }
    }
  }

  .back {
    background-color: #EEF1EF;
  }








  .contact-section {
    display: flex;
    justify-content: center;
    justify-items: center;
    padding: 40px 20px;

    background-color: white;
    margin-bottom: 100px;

  }

  .contact-left {
    margin-top: 150px;
    margin-right: 150px;

    h3 {
      font-size: 1rem;
      color: #28a745;
      font-weight: bold;
      margin-bottom: 10px;
    }

    h2 {
      font-size: 2rem;
      font-weight: 600;
      color: #000;
    }
  }

  .contact-right {

    width: 35%;
    background-color: #f3f3f3;
    padding: 20px;
    border-radius: 5px;
  }

  .contact-info {
    margin-bottom: 20px;

    h4 {
      font-size: 1.2rem;
      color: #000;
      margin-bottom: 5px;
      font-weight: bold;
    }

    p {
      font-size: 1rem;
      color: #666;
      display: flex;
      align-items: center;

      .icon {
        width: 16px;
        margin-right: 10px;
      }

      a {
        color: #000;
        text-decoration: none;
      }
    }
  }

  .hours {
    list-style-type: none;
    padding-left: 0;

    li {
      font-size: 1rem;
      color: #666;
    }
  }



}
</style>
