/*
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-10-12 16:38:56
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-12-09 10:18:10
 * @FilePath: \cloud\src\router\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// src/router/index.js

import Vue from 'vue';
import VueRouter from 'vue-router';

// 引入其他组件
import Home from '../views/Home.vue';
import contactus from '@/views/contactus.vue';
import about from '../views/about.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import CellRenewalNAD from '../views/products/CellRenewalNAD.vue';
import Advanced3in1Jointcarecomplex  from '../views/products/Advanced3in1Jointcarecomplex.vue'
import Colostrumtablets from '../views/products/Colostrum-tablets.vue'
import DHSPropolisPowercapsule from '../views/products/DHS-Propolis-Power-capsule.vue'
import EyeExcelwithBilberry10000mg from '../views/products/Eye-Excel-with-Bilberry-10000mg.vue'
import LiverCarewithMilkThistle40000mg from '../views/products/Liver-Care-with-Milk-Thistle-40000mg.vue'
import Pacificallinone from '../views/products/Pacific-all-in-one.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: contactus
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/products/Advanced3in1Jointcarecomplex', // 懒加载 product1 组件
    name: 'Advanced-3-in-1-Joint-care-complex',
    component: Advanced3in1Jointcarecomplex
  },

  {
    path: '/products/CellRenewalNAD', // 懒加载 product1 组件
    name: 'CellRenewalNAD',
    component: CellRenewalNAD// 懒加载方式
  },
  {
    path: '/products/Colostrum-tablets', // 懒加载 product1 组件
    name: 'Colostrum tablets',
    component: Colostrumtablets // 懒加载方式
  },
  {
    path: '/products/DHS-Propolis-Power-capsule', // 懒加载 product1 组件
    name: 'DHS-Propolis-Power-capsule',
    component: DHSPropolisPowercapsule// 懒加载方式
  },
  {
    path: '/products/Eye-Excel-with-Bilberry-10000mg', // 懒加载 product1 组件
    name: 'Eye-Excel-with-Bilberry-10000mg',
    component: EyeExcelwithBilberry10000mg
  },
  {
    path: '/products/Liver-Care-with-Milk-Thistle-40000mg', // 懒加载 product1 组件
    name: 'Liver-Care-with-Milk-Thistle-40000mg',
    component: LiverCarewithMilkThistle40000mg // 懒加载方式
  },
  {
    path: '/products/Pacific-all-in-one', // 懒加载 product1 组件
    name: 'Pacific-all-in-one',
    component: Pacificallinone
  },
 
];

const router = new VueRouter({
  mode: 'history', // 使用 HTML5 History 模式
  routes
});

export default router;
