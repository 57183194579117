<template>
  <div class="main">
    <div class="container">
      <!-- 联系我们部分 -->
      <div class="contact-us-section">
        <div class="contact-us-content" style="margin-left: 100px">
          <h2 class="title" style="text-align: left">联系我们</h2>
          <p>
            光辉云可利用AWS云技术全面优化您的业务。欢迎随时联系，我们将为您提供量身定制的方案与支持，助力您的业务实现更高效的运营和持续发展。
          </p>
          <p><strong>电子邮件:</strong> emogocoyu831@gmail.com</p>
          <p><strong>电话:</strong> +86 15014044754</p>
        </div>
        <div class="contact-us-image"></div>
      </div>

      <div class="cu-box-flex">
        <div class="cu-box">
          <div class="cu-box-margin">
            <div
              class="from-title"
              style="font-size: 31px; color: #3fa3ff; font-weight: 600"
            >
              联系我们
            </div>
            <div class="cu-box-margin contact-item">
              <a target="_blank" href="https://t.me/aws725">
                <img
                  class="contact-icon"
                  src="@/assets/widgetApp/telegram.svg"
                  alt="Telegram"
                />
                <span>@aws725</span>
              </a>
            </div>
            <div class="cu-box-margin contact-item">
              <a
                target="_blank"
                href="https://api.whatsapp.com/send/?phone=%2B8615019435823&text&type=phone_number&app_absent=0"
              >
                <img
                  class="contact-icon"
                  src="@/assets/widgetApp/WHATSAPP.svg"
                  alt="WhatsApp"
                />
                <span>+86 15019435823</span>
              </a>
            </div>
          </div>
        </div>

        <div class="from-box">
          <form
            class="form-box"
            action="https://formspree.io/f/mwkgdyjq"
            method="post"
          >
            <label for="name">姓名：</label>
            <input
              id="name"
              class="form-input"
              type="text"
              placeholder="您的姓名"
              name="姓名"
            />

            <label for="doc-vld-ta-2-1">请输入您的留言：</label>
            <textarea
              class="form-input"
              name="留言"
              id="doc-vld-ta-2-1"
              placeholder="请输入您的留言"
            ></textarea>
            <div class="banner_btn">
              <button class="el-button el-button--primary btn" type="submit">
                发送信息
              </button>
            </div>
          </form>
        </div>
      </div>

      <!-- 我们的优势 部分 -->
      <div class="adv-container">
        <h2 class="title">我们的优势</h2>
        <div class="features">
          <div
            class="adv-feature"
            v-for="(advantages, index) in advantages"
            :key="index"
          >
            <div class="feature-icon">
              <img :src="advantages.icon" />
            </div>
            <div class="adv-feature-title">{{ advantages.title }}</div>
            <div class="feature-description">
              {{ advantages.description }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <br />
    <br />
    <br />
    <br />

    <footer class="footer-section">
      <div class="footer-container">
        <!-- 左侧部分，品牌标识和介绍 -->
        <div class="footer-left">
          <img class="footer-logo" src="@/assets/logo.png" alt="光辉 云 Logo" />
          <p class="footer-description">
            关于 光辉云
            我们的使命和愿景是开发最安全、更高效的云资源。我们很自豪能够提供最好的云计算产品和解决方案。
          </p>
          <p class="footer-email">电子邮件：emogocoyu831@gmail.com</p>
          <p class="footer-copyright">
            <a href="/PrivacyPolicy"
              >隐私政策 | 网站条款 © 2023, 光辉 云, Inc.</a
            >
            或其关联公司。保留所有权利。
          </p>
        </div>

        <!-- 右侧部分，友情链接和社交图标 -->
        <div class="footer-right">
          <!-- <div class="social-icons">
            <a href="#"><img src="@/assets/logo.png" alt="Telegram" /></a>
            <a href="#"><img src="@/assets/logo.png" alt="WhatsApp" /></a>
          </div> -->
        </div>
      </div>
    </footer>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      advantages: [
        {
          title: "优质技术支持",
          description:
            "光辉云结合AWS的强大支持体系，提供24/7全天候专业技术支持，帮助客户迅速解决问题，确保业务稳定运行。",
          icon: require("@/assets/icons/contactus-1.png"), // 确保替换为实际的图标路径
        },
        {
          title: "自动化故障转移",
          description:
            " 借助AWS的故障转移机制（如跨区域复制和容灾方案），确保即使在意外发生时，服务也能快速恢复，保持业务连续性。",
          icon: require("@/assets/icons/contactus-2.png"), // 确保替换为实际的图标路径
        },
        {
          title: "可靠防护",
          description:
            " 光辉云通过AWS云服务器提供多层次的安全防护，包括数据加密、访问控制、DDoS防护等，确保业务数据的高度安全性。",
          icon: require("@/assets/icons/contactus-3.png"), // 确保替换为实际的图标路径
        },
        {
          title: "高度安全的云架构",
          description:
            " AWS通过加密技术、多因子身份验证和虚拟私有云（VPC）等安全手段，为客户的数据提供端到端保护，光辉云可为客户设计安全性极高的云架构。",
          icon: require("@/assets/icons/contactus-4.png"), // 确保替换为实际的图标路径
        },
        {
          title: "按需付费，优化成本",
          description:
            "企业只需为消耗的计算、存储和网络资源支付费用。光辉云可帮助客户优化资源分配和使用，利用如“预留实例”和“节省计划”等策略，进一步降低长期成本。",
          icon: require("@/assets/icons/contactus-5.png"), // 确保替换为实际的图标路径
        },
        {
          title: "数据分析与大数据支持",
          description:
            "AWS提供多种大数据处理服务，如Amazon Redshift、EMR和Kinesis，帮助企业快速处理和分析海量数据，洞察商业机会。",
          icon: require("@/assets/icons/contactus-6.png"), // 确保替换为实际的图标路径
        },
      ],
    };
  },
};
</script>
  
<style>
.main {
  margin: 0;
  padding: 0;
}

.cu-box-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: 5% auto;
  padding: 0 20px;
}

.cu-box {
  flex: 1;
  min-width: 300px;
  margin-bottom: 30px;
}

.from-box {
  flex: 1;
  min-width: 300px;
}

.form-box {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.form-input {
  display: block;
  width: 100%;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #cacaca;
  background-color: #fbfcfe;
  color: black;
  outline: none;
  transition: background-color 0.3s ease;
}

.form-input:focus {
  background-color: rgb(255, 255, 255);
  color: black;
  border: 1px solid #0054b5;
}

textarea.form-input {
  height: 170px;
  resize: vertical;
}

.cu-box-margin {
  margin-top: 20px;
}

.from-title {
  font-size: 24px;
  color: #3fa3ff;
  font-weight: 600;
}

.contact-item {
  margin-top: 20px;
}

.contact-item a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.contact-icon {
  width: 30px;
  height: 30px;
  background-color: aliceblue;
  border-radius: 50%;
  margin-right: 10px;
}

.btn {
  background-color: #3fa3ff;
  height: 50px;
  width: 100%;
  max-width: 150px;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #3080d0;
}

@media (max-width: 768px) {
  .cu-box-flex {
    flex-direction: column;
  }

  .cu-box, .from-box {
    width: 100%;
  }

  .from-title {
    font-size: 24px;
  }

  .form-input {
    font-size: 16px;
  }

  .btn {
    width: 100%;
    max-width: none;
  }
}

@media (min-width: 768px) {
  .container {
    font-family: sans-serif;
  }

  .contact-us-section {
    background-color: #f8e9e4;
    padding: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative; /* 用于定位浮动图标 */
  }

  .contact-us-content {
    flex: 1;
  }

  .contact-us-image {
    flex: 1;
    /* 可以在这里添加图片样式 */
  }

  /* .icon {
    width: 40px;
    height: 40px;
    background-color: #ccc;
    border-radius: 50%;
    margin-bottom: 10px;
  } */

  /* ...其他样式... */
 
  .adv-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;

    .title {
      text-align: center;
      margin-bottom: 40px;
      color: #333;
    }

    .features {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-gap: 20px;
    }

    .adv-feature {
      border: 1px solid #eee;
      box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.2);
      padding: 20px;
      text-align: center;
    }

    .feature-icon {
      width: 60px;
      height: 60px;
      margin: 0 auto 20px;
    }

    .feature-icon img {
      width: 100%;
      height: 100%;
    }

    .adv-feature-title {
      font-weight: bold;
      margin-bottom: 10px;
      color: #333;
    }

    .feature-description {
      color: #666;
      line-height: 1.5;
    }
  }

  .footer-section {
    background-color: #1c1c1c;
    color: #ffffff;
    padding: 20px 0;

    .footer-container {
      display: flex;
      justify-content: space-between;
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 20px;

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
    }

    .footer-left {
      max-width: 50%;

      @media (max-width: 768px) {
        max-width: 100%;
        text-align: center;
      }

      .footer-logo {
        width: 65px;
        margin-bottom: 10px;
      }

      .footer-description {
        font-size: 14px;
        margin-bottom: 10px;
        line-height: 1.6;
      }

      .footer-email {
        font-size: 14px;
        margin-bottom: 20px;
      }

      .footer-copyright {
        font-size: 12px;
        color: #888888;
      }
    }

    .footer-right {
      max-width: 40%;
      text-align: right;

      @media (max-width: 768px) {
        max-width: 100%;
        text-align: center;
        margin-top: 20px;
      }

      h4 {
        font-size: 16px;
        margin-bottom: 10px;
      }

      .footer-links {
        list-style: none;
        padding: 0;
        margin: 0 0 20px;

        li {
          margin-bottom: 5px;

          a {
            color: #ffffff;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .social-icons {
        display: flex;
        justify-content: flex-end;

        @media (max-width: 768px) {
          justify-content: center;
        }

        a {
          display: inline-block;
          margin-left: 10px;

          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
}
</style>
  