<template>
  <div>


    <div class="all" v-if="showHomeAndroid == false">
      <div class="main">
        <div class="navbar">
          <div class="logo">
            <!-- 添加 @click 事件到 DHS Daily Health Supplement 标题 -->
            <h1 @click.prevent="handleHomeClick" style="cursor: pointer;">DHS DAILY HEALTH SUPPLEMENT</h1>
          </div>
          <nav>
            <ul class="nav-links">
              <li><a @click.prevent="handleHomeClick">Home</a></li>
              <li><a @click.prevent="handleAboutClick">About</a></li>
              <li class="dropdown">
                <a @click.prevent="handleProductsClick">Products</a>
                <ul class="dropdown-content">
                  <li><a @click.prevent="handleProductsClick">Product 1</a></li>
                  <li><a @click.prevent="handleProductsClick">Product 2</a></li>
                  <li><a @click.prevent="handleProductsClick">Product 3</a></li>
                </ul>
              </li>
            </ul>
          </nav>
          <div class="contact-button">
            <a @click.prevent="handleContactClick" class="btn">CONTACT</a>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showHomeAndroid == true">
      <div class="mobile-navbar">
        <div class="mobile-header">
          <h1 @click.prevent="handleHomeClick" class="mobile-logo">DHS DAILY HEALTH <br>SUPPLEMENT</h1>
          <div class="menu-icon" @click="toggleMenu">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
        </div>
        <transition name="slide-fade">
          <!-- 使用 v-if 控制菜单显示，并应用动画 -->
          <ul v-if="menuOpen" class="mobile-menu">
            <li><a @click.prevent="handleHomeClick">Home</a></li>
            <li><a @click.prevent="handleAboutClick">About</a></li>
            <li @click="toggleProductsDropdown" class="dropdown">
              <a>Products</a>
              <transition name="slide-fade">
                <ul v-if="productsDropdownOpen" class="dropdown-content">
                  <li><a @click.prevent="handleProductsClick">Product 1</a></li>
                  <li><a @click.prevent="handleProductsClick">Product 2</a></li>
                  <li><a @click.prevent="handleProductsClick">Product 3</a></li>
                </ul>
              </transition>
            </li>
            <li><a @click.prevent="handleContactClick">Contact</a></li>
          </ul>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showHomeAndroid: false,
      screenWidth: null,
      // 下面估计不是的

      menuOpen: false,
      productsDropdownOpen: false,
    };
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },

  watch: {
    screenWidth: {
      handler: function (val) {
        if (val >= 800) {
          this.bodyScale();
          this.showHomeAndroid = false;
          console.log(this.showHomeAndroid);
        } else if (val < 800) {
          this.showHomeAndroid = true;
          console.log(this.showHomeAndroid);
        }
        this.$forceUpdate();
      },
      immediate: true,
      deep: true,
    },
  },



  methods: {

    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    toggleProductsDropdown() {
      this.productsDropdownOpen = !this.productsDropdownOpen;
    },
    handleHomeClick() {
      // 跳转到主页
      this.$router.push('/');
    },
    handleAboutClick() {
      // 跳转到关于页面
      this.scrollToSection('about');
    },
    handleProductsClick() {
      // 跳转到产品页面
      this.scrollToSection('products');
    },
    handleContactClick() {
      // 跳转到联系页面
      this.scrollToSection('contact');
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    },


    bodyScale() {
      var devicewidth = document.documentElement.clientWidth; //获取当前分辨率下的可是区域宽度
      var scale = devicewidth / 1600; // 分母——设计稿的尺寸
      document.body.style.zoom = scale; //放大缩小相应倍数
    },






    handleHomeClick() {
      if (this.$route.path !== '/') {
        this.$router.push('/').then(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        });
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    },
    handleAboutClick() {
      if (this.$route.path !== '/') {
        this.$router.push('/').then(() => {
          this.scrollToSection('about');
        });
      } else {
        this.scrollToSection('about');
      }
    },
    handleProductsClick() {
      if (this.$route.path !== '/') {
        this.$router.push('/').then(() => {
          this.scrollToSection('products');
        });
      } else {
        this.scrollToSection('products');
      }
    },
    handleContactClick() {
      if (this.$route.path !== '/') {
        this.$router.push('/').then(() => {
          this.scrollToSection('contact');
        });
      } else {
        this.scrollToSection('contact');
      }
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
};
</script>

<style scoped>
@media (max-width: 768px) {
  .all {
    background-color: rgb(255, 255, 255);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
  }

  .mobile-navbar {
    background-color: #fff;
    padding: 10px 0px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
  }

  .mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-logo {
    margin-left: 20px;
    font-size: 20px;
    font-weight: bold;
    color: #000;
  }

  .menu-icon {
    margin-right: 20px;
    width: 33px;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
  }

  .menu-icon .line {
    height: 4px;
    background-color: #232323;
    border-radius: 1px;
  }

  .mobile-menu {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 130pxx;
    width: 100%;
    opacity: 1;
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  .mobile-menu li {
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
    line-height: 40px;
  }

  .mobile-menu a {
    text-decoration: none;
    color: #333;
    font-size: 16px;
    display: block;
  }

  /* 下拉菜单 */
  .dropdown-content {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #f9f9f9;
  }

  .dropdown-content li {
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
  }

  /* Slide and fade transition */
  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  .slide-fade-enter,
  .slide-fade-leave-to

  /* .slide-fade-leave-active for <2.1.8 */
    {
    opacity: 0;
    transform: translateY(-10px);
  }
}










.all {
  background-color: rgb(255, 255, 255);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.main {
  width: 53%;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo h1 {
  font-size: 22px;
  line-height: 120px;
  font-weight: bold;
  color: #000;
  margin: 0;
  cursor: pointer;
  /* 添加手型指针效果 */
}

nav {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.nav-links {
  list-style-type: none;
  display: flex;
  gap: 20px;
}

.nav-links li {
  display: inline;
}

.nav-links a {
  text-decoration: none;
  font-size: 18px;
  color: #333;
  cursor: pointer;
  transition: color 0.3s ease, border-bottom 0.3s ease;

}

.nav-links a:hover {
  color: #28a745;
  border-bottom: 2px solid #28a745;
  text-decoration: none;
  font-weight: bold;
}

/* Dropdown styling */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.dropdown-content li {
  list-style-type: none;
}

.dropdown-content a {
  color: #333;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
  color: #000;
}

/* Show dropdown on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Contact button */
.contact-button .btn {
  font-size: 18px;
  padding: 17px 35px;
  color: black;
  border: 3px solid black;
  text-decoration: none;
  background: white;
  border-radius: 0%;
  font-weight: 550;
}

.contact-button .btn:hover {
  background-color: #32AA27;
  border: 3px solid #32AA27;
  color: white;
}
</style>
