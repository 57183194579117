<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2024-08-26 10:12:43
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-12-04 09:17:54
 * @FilePath: \0826\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->


<template>
  <div id="app">
    
    
    <Navbar />

    <router-view></router-view> <!-- 渲染路由组件 -->


<!-- 
    <div class="right-bottom-widget">
      <div class="us-widget" v-for="i in us_widget_list" :key="i.index"
        :style="{ '--shadow-color': 'rgba(100, 100, 100, 0.3)' }">
        <a :href="i.url">
          <div @mouseenter="ShowWidget(i.title)" @mouseleave="IsShowWidget(i.title)">
            <img :src="i.img" :alt="i.title" />
          </div>
        </a>
        <div v-if="i.showImg == true" class="widget-img">
          <div><img :src="i.ImgDiv" alt="i.title" /></div>
        </div>
      </div>
    </div>-->
  </div> 






</template>

<script>
import Home from './views/Home.vue'
import Navbar from './views/Navbar.vue'
export default {
  name: 'App',


  data() {
    return {
      us_widget_list: [
        {
          index: "1",
          title: "telegram",
          img: require("@/assets/widgetApp/telegram.svg"),
          url: "https://t.me/aws725",
        },
        {
          index: "2",
          title: "whatsapp",
          img: require("@/assets/widgetApp/WHATSAPP.svg"),
          url: "https://api.whatsapp.com/send/?phone=%2B37376033725&text&type=phone_number&app_absent=0",
          ImgDiv: require("@/assets/widgetApp/whatsApp.png"),
          showImg: false,
        },
        {
          index: "3",
          title: "微信",
          img: require("@/assets/widgetApp/weixin.svg"),
          // url: "",
          ImgDiv: require("@/assets/widgetApp/leek_wx.png"),
          showImg: false,
        },
        {
          index: "4",
          title: "facebook",
          img: require("@/assets/widgetApp/facebook_facebook52.svg"),
          url: "https://www.facebook.com/profile.php?id=61565578510634",
        },
      ],
    }
  },
  components: {
    Home,
    Navbar,
  },
  methods: {
    ShowWidget(title) {
      for (let i = 0; i <= this.us_widget_list.length; i++) {
        if (
          this.us_widget_list[i].title == title &&
          this.us_widget_list[i].ImgDiv != null
        ) {
          this.us_widget_list[i].showImg = true;
        } else {
          this.us_widget_list[i].showImg = false;
        }
      }
    },
    IsShowWidget(title) {
      for (let i = 0; i <= this.us_widget_list.length; i++) {
        if (
          this.us_widget_list[i].title == title &&
          this.us_widget_list[i].ImgDiv != null
        ) {
          this.us_widget_list[i].showImg = false;
        }
      }
    },
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin: 0;
  padding: 0;













}
</style>
